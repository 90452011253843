<template>
  <div id="Title">
    <div class="title-box">
      <div class="title">
        <p>BrinsStudio</p>
        <div style="display:flex;">
          <a href="http://kw.brins.top" class="btn-title">前往卡网</a>
          <a href="http://kw.brins.top" class="btn-title">联系我们</a>
        </div>
      </div>
      <p class="info"></p>
    </div>
  </div>
</template>

<script>
export default {
    name: "Title",
}
</script>

<style scoped lang="less">

#Title {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/ah.jpg");
    background-repeat: no-repeat;
    background-position:top;
    background-size: cover;
}

.title-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-in-out;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    border: 4px solid black;
    padding: 50px;
    padding-left: 60px;
    padding-right: 60px;
    font-size: 70px;
    font-weight: 1000;
    transition: all 1s ease-in-out;
}

.title:hover {
  box-shadow: 3px 3px 25px rgba(65, 65, 65, 0.486);
}

.btn-title {
  display: block;
  padding:10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  background-color: rgb(51, 163, 255);
  color: rgba(255,255,255);
  border: 0px solid black;
  border-radius: 5px;
  transition: all 1s ease-in-out;
  margin: 40px;
  margin-block: 0px;
  margin-top: 40px;
}

.btn-title:hover {
  box-shadow: 3px 3px 25px rgba(65, 65, 65, 0.486);
  background-color: rgba(51, 163, 255,0);
  border: 1px solid black;
  color: rgb(51, 163, 255);
}

.title-box:hover {
  
}

@keyframes tb {
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 70%;
  }
  100% {
    height: 90%;
  }
}

</style>