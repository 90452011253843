<template>
  <div id="footer">
    <footer>
        <div class="footer-item">2022© Brins Studio All Rights Resaved</div>
    </footer>
  </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>

</style>