<template>
  <div id="app">
    <Nav />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue"
import Main from "./components/Main.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  components: {
    Main,
    Nav,
    Footer
},
};
</script>

<style lang="less">

/* main.css START */
* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  background-color: aliceblue;
}

img.nav-img {
  padding: 0px;
  margin-top: 2px;
  height: 40px;
  margin-left: 15px;
}

.nothing {
  width: 40%;
}

.nav {
  display: flex;
  flex-direction: row;
  background-color: rgb(51, 163, 255);
  list-style: none;
  font-weight: bold;
  padding: 10px;
  justify-content: start;
  align-items: flex-end;
  z-index: 100;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

a {
  text-decoration: none;
  display: block;
}

.nav-items {
  display: flex;
  justify-content: end;
  width: 97%;
}

.nav-item {
  margin: 2px;
  padding: 5px 20px;
  display: block;
  transition: 1s;
  margin-bottom: 9px;
}

.nav-item:hover {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  font-size: 18px;
  transition: 1s;
  box-shadow: 3px 3px 25px rgba(65, 65, 65, 0.486);
}

.text-write {
  color: aliceblue;
}

.text-write:hover {
  color: rgb(51, 163, 255);
  transition: 0.5s;
}

.back-bottom {
  overflow: hidden;
  display: flex;
  background-color: #0091ea;
  color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-weight: bold;
  width: 67px;
  transition: width 1s;
  -webkit-transition: width 0.8s;
  max-height: 30px;
}

.arror {
  display: none;
  margin-left: 30px;
  transition: margin 1s;
  -webkit-transition: margin 1s;
}

.back-bottom:hover {
  width: 115px;
}

.back-bottom:hover .arror {
  display: block;
  font-weight: bold;
}

footer {
  background-color: #29b6f6;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  positon:fixed;
  bottom:0;
}

.footer-item {
  padding: 2px 10px;
  background-color: #eee;
  border-radius: 5px;
  transition: 1s;
}

.footer-item:hover {
  box-shadow: 3px 5px 10px rgba(65, 65, 65, 0.486);
  right: 10px;
}

@keyframes up {
  from {
    transform: translateY(150px);
  }
  to {
    transform: translateY(0px);
  }
}

@media (max-width: 900px) {
  .nothing {
    width: 8%;
  }
}
</style>
