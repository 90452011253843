<template>
    <div id="nav">
        <ul class="nav">
            <li><img class="nav-img" src="../assets/icon.png" alt="Brins Studio" href="#"></li>
            <div class="nav-items">
                <li class=""><a href="#" class="text-write nav-item">主页</a></li>
                <li class=""><a href="//fourm.brins.top" class="text-write nav-item">论坛</a></li>
                <li class=""><a href="//status.brins.top" class="text-write nav-item">服务器状态</a></li>
                <li class=""><a href="//" class="text-write nav-item">联系我们</a></li>
                <li class=""><a href="//kw.brins.top" class="text-write nav-item">卡网</a></li>
            </div>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Nav",
}
</script>

<style>

</style>