<template>
  <div id="main">
    <Title />
  </div>
</template>

<script>
import Title from './Title.vue'
export default {
  components: { Title },
  name: 'Main',
}
</script>


